<template>
    <div class="header" v-if="!isMobile">
        <div class="left">
            <img src="@/assets/logo.png" />
        </div>
    </div>
    <div class="top" v-else>
        <div class="left">
            <img src="@/assets/logo.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: "index",

    data() {
        return {
            isMobile: false,
        }
    },

    mounted() {
        this.isMobile = this.$isMobile();
    },

    methods: {

    }
}
</script>

<style lang="scss" scoped>
    .header {
        background: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 8px 110px;
        .left{
            height: 54px;
            img{
                height: 54px;
            }
        }
    }
    .top{
        background: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 8px 30px;
        .left{
            img{
                height: 30px;
            }
        }
    }
</style>
