<template>
    <div id="app">
        <header-main />
        <router-view />
        <footer-main />
    </div>
</template>
<script>
    import HeaderMain from '@/components/Header/index';
    import FooterMain from '@/components/Footer/index';
    export default {
        components: {
            HeaderMain,
            FooterMain
        },

        mounted() {
            window.addEventListener('resize', function () {
                location.reload()
            });
        }
    }
</script>
<style lang="scss">

</style>
