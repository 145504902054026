<template>
    <div class="footer">
        © 2022. {{ company }} <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">粤ICP备19121250号</a>
    </div>
</template>

<script>
export default {
    name: "index",

    data(){
        return {
            company: '广州博数信息科技有限公司'
        }
    },

    mounted() {
        let isMobile = this.$isMobile();
        if(isMobile) {
            this.company = '博数信息'
        }else{
            this.company = '广州博数信息科技有限公司'
        }
    },

    methods: {

    }
}
</script>

<style lang="scss" scoped>
    .footer{
        text-align: center;
        padding: 25px 0;
        background: #FFFFFF;
        color: #bbb;
        font-size: 14px;
        a{
            color: #bbb;
            text-decoration-line: none;
        }
        a:hover{
            color: #040415;
        }
    }
</style>
