import Vue from "vue";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/common.scss'

import utils from './utils/utils.js'
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(utils)
Vue.use(ElementUI , {
    size: 'small' // set element-ui default size
})

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
